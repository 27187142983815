* {
    font-family: ten-oldstyle, serif;
}

#root {
    overflow: hidden;
    position: relative;
}

:root {
    --dark-brown-color: #4F453C;
    --white-color: #ffffff;
    --bronze-color: #B08D50;
    --silver-color: #9B9686;
    --gold-color: #E5B765;
    --banner-image-color: #D6CAB7;
    --paper-color: #f3ebe3;
    --blue-color: #12284B;
    --dark-blue-color: #475A72;
    --content-max-width: 1266px;    
    --primary-font: ten-oldstyle, serif;
    --secondary-font: 'Poppins', sans-serif;
    --content-min-height: 700px;
    --content-min-width: 1000px;
}

.homepage .MobileNotification {
    display: none;
}

.unauthorized-email-domain .Notification.is-visible-true {
    display: block !important;
}

@keyframes fillPath {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes cloudOne {
    0% {
        top: -10%;
        left: -30%;      
    }

    50% {
        top: -10%;
        left: -20%;
    }

    100% {
        top: -10%;
        left: -30%;
    }
}

@keyframes cloudTwo {
    0% {
        top: 0%;
        right: -10%;      
    }

    50% {
        top: 0%;
        right: 0%;
    }

    100% {
        top: 0%;
        right: -10%;
    }
}

@keyframes cloudThree {
    0% {
        bottom: -40%;
        left: -10%;      
    }

    50% {
        bottom: -35%;
        left: 10%;
    }

    100% {
        bottom: -40%;
        left: -10%;
    }
}

@keyframes cloudFour {
    0% {
        bottom: -10%;
        right: -10%;      
    }

    50% {
        bottom: 0%;
        right: 0%;
    }

    100% {
        bottom: -10%;
        right: -10%;
    }
}

@keyframes cloudFive {
    0% {
        bottom: 0%;
        left: 20%;      
    }

    50% {
        bottom: 0%;
        left: 0%;
    }

    100% {
        bottom: 0%;
        left: 20%;
    }
}

@keyframes cloudSix {
    0% {
        top: -20%;
        left: -30%;      
    }

    50% {
        top: -10%;
        left: -70%;
    }

    100% {
        top: -20%;
        left: -30%;
    }
}

@keyframes cloudSeven {
    0% {
        top: -18%;
        right: -17%;      
    }

    50% {
        top: -20%;
        right: 17%;
    }

    100% {
        top: -18%;
        right: -17%;
    }
}

@keyframes cloudEight {
    0% {
        bottom: -48%;
        left: 5%;      
    }

    50% {
        bottom: -48%;
        left: 100%;
    }

    100% {
        bottom: -48%;
        left: 5%;
    }
}

@keyframes cloudNine {
    0% {
        bottom: -30%;
        right: -20%;      
    }

    50% {
        bottom: -30%;
        right: 20%;
    }

    100% {
        bottom: -30%;
        right: -20%;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;      
    }

    100% {
        opacity: 1;
    }
}

@keyframes compass {
    0% {
        transform: rotate(-6deg);      
    }

    50% {
        transform: rotate(6deg);
    }

    100% {
        transform: rotate(-6deg);
    }
}

@keyframes oceanWavesOne {
    0% {
        transform: skew(0deg, 0deg);
        opacity: 1;      
    }

    50% {
        transform: skew(4deg, 0.5deg);
        opacity: 0.5;
    }

    100% {
        transform: skew(0deg, 0deg);
        opacity: 1;  
    }
}

@keyframes oceanWavesTwo {
    0% {
        transform: skew(0deg, 0deg);
        opacity: 0.5;      
    }

    50% {
        transform: skew(4deg, 0.5deg);
        opacity: 1;
    }

    100% {
        transform: skew(0deg, 0deg);
        opacity: 0.5;  
    }
}

@keyframes oceanWavesThree {
    0% {
        transform: skew(0deg, 0deg);
        opacity: 1;      
    }

    50% {
        transform: skew(4deg, 0.5deg);
        opacity: 0.25;
    }

    100% {
        transform: skew(0deg, 0deg);
        opacity: 1;  
    }
}


@keyframes terrain {
    0% {
        transform: skew(0deg, 0deg);      
    }

    50% {
        transform: skew(0deg, 0.5deg);
    }

    100% {
        transform: skew(0deg, 0deg);
    }
}

@keyframes dragonRotation {
    0% {
        transform: rotate(1deg) translateZ(0px);      
    }

    50% {
        transform: rotate(-1deg) translateZ(0px);
    }

    100% {
        transform: rotate(1deg)translateZ(0px);
    }
}
